.header-container {
    border-bottom: solid !important;
    border-color: #00447c !important;
    border-width: .5px;
}
.justify-content-end {
    padding-right: 150px;
}
.logo {
    margin-left: 100px;
    padding-left: 50px;
    max-width: 300px;
    min-width: 300px;
}
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}
.nav-link {
    font-size: 1.0rem;
    font-family: "Montserrat";
    font-weight: 600;
    color: #00447c !important;
    padding: 21px 14px 10px !important;
}
.nav-link:hover {
    font-size: 105% !important;
}
.dropdown-menu {
    background-color: none!important;
    border-width: 0px !important;
    border-style: none !important;
    border-color: none!important;
    font-weight: 600 !important;
    color: #00447c !important;
}
.dropdown-menu-toggler:focus,
.dropdown-menu-toggler:active,
.dropdown-menu-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}
.dropdown-toggle {
    border: 2px !important;
    border-color: #00447c !important;
}
/* .dropdown-menu {
    background-color: white !important;
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
    border-left: none !important;
    border-right: none !important;
    border-color: #00447c important;
    font-weight: 600 !important;
    color: #00447c !important;
} */
.dropdown-item {
    background-color: white !important;

    font-weight: 600 !important;
    color: #00447c !important;
}
.dropdown-item:hover {
    background-color: white !important;
    color: #00447c !important;
    font-size: 102%;
}
/* Theme of the UE main website is to highlight the link to the current page in yellow  */
.yellow-item {
    margin-top: -150px;
    font-size: 1.10rem;
    font-weight: 600 !important;
    color: #fed000 !important;
}
.yellow-item:hover{
    font-size: 110%;
}
.blue-button-header {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
  }
  .blue-button-header:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }


@media (max-width: 1200px) {
    .justify-content-end {
        padding-right: 50px;
    }

   .logo {
       margin-left: 50px;
       padding-left: 25px;
       max-width: 250px;
       min-width: 250px;
   }
 }


/* This is to address automatic bootstrap sizing changes to dropdown menu */

@media (max-width: 992px) {
    .logo {
        margin-left: 30px;
        padding-left: 0px;
        max-width: 200px;
        min-width: 200px;
    }
    .navbar-collapse {
        padding-right: 0px;
    }
    .navbar {
        padding-right: 0rem;
    }
    .dropdown-menu {
        text-align: center !important;
    }
    .justify-content-end {
        padding-right: 0px;
    }
    .button-continer {
        align-items: center;
    }
}

@media (max-width: 780px) {
    .navbar-brand {
        font-size: 1.0rem;
    }
    .nav-brand:hover {
        text-decoration: none;
        color: white;
        font-size: 1.0rem;
    }
    /* Theme of the UE main website is to highlight the link to the current page in yellow  */
    .yellow-link {
        font-size: 1.50rem;
    }
}
@media (max-width: 780px) {
    .logo {
        margin-left: 15px;
        padding-left: 0px;
        max-width: 200px;
        min-width: 200px;
    }
    .navbar-brand {
        font-size: 1.0rem;
    }
    .nav-brand:hover {
        text-decoration: none;
        color: white;
        font-size: 2.25rem;
    }
}
@media (max-width: 400px) {
    .yellow-item {
        font-size: 1.25rem;
    }
}