@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  font-family: Montserrat, sans-serif;
  background-color: transparent;
}
body {
    font-family: "Montserrat";
    /* -apple-system, BlinkMacSystemFont; */
    color:#1e2022;
    background-color: white;
    text-align: center;
    width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat",sans-serif;
  font-style: bold;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Toastify__toast-body {
  font-weight: bold;
  color: #00447c;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header-container {
    border-bottom: solid !important;
    border-color: #00447c !important;
    border-width: .5px;
}
.justify-content-end {
    padding-right: 150px;
}
.logo {
    margin-left: 100px;
    padding-left: 50px;
    max-width: 300px;
    min-width: 300px;
}
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}
.nav-link {
    font-size: 1.0rem;
    font-family: "Montserrat";
    font-weight: 600;
    color: #00447c !important;
    padding: 21px 14px 10px !important;
}
.nav-link:hover {
    font-size: 105% !important;
}
.dropdown-menu {
    background-color: none!important;
    border-width: 0px !important;
    border-style: none !important;
    border-color: none!important;
    font-weight: 600 !important;
    color: #00447c !important;
}
.dropdown-menu-toggler:focus,
.dropdown-menu-toggler:active,
.dropdown-menu-icon:focus {
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #00447c !important;
    background-color: none !important;
}
.dropdown-toggle {
    border: 2px !important;
    border-color: #00447c !important;
}
/* .dropdown-menu {
    background-color: white !important;
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
    border-left: none !important;
    border-right: none !important;
    border-color: #00447c important;
    font-weight: 600 !important;
    color: #00447c !important;
} */
.dropdown-item {
    background-color: white !important;

    font-weight: 600 !important;
    color: #00447c !important;
}
.dropdown-item:hover {
    background-color: white !important;
    color: #00447c !important;
    font-size: 102%;
}
/* Theme of the UE main website is to highlight the link to the current page in yellow  */
.yellow-item {
    margin-top: -150px;
    font-size: 1.10rem;
    font-weight: 600 !important;
    color: #fed000 !important;
}
.yellow-item:hover{
    font-size: 110%;
}
.blue-button-header {
    white-space: nowrap;
    display: inline-block !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: flex-end !important;
    align-items: stretch !important;
    border-radius: 8px !important;
    background-color: #00447c !important;
    font-weight: 500 !important;
    text-align: center !important;
    max-width: 200px !important;
  }
  .blue-button-header:hover {
    background-color: #fed000 !important;
    color: #000;
    font-weight: 600;
  }


@media (max-width: 1200px) {
    .justify-content-end {
        padding-right: 50px;
    }

   .logo {
       margin-left: 50px;
       padding-left: 25px;
       max-width: 250px;
       min-width: 250px;
   }
 }


/* This is to address automatic bootstrap sizing changes to dropdown menu */

@media (max-width: 992px) {
    .logo {
        margin-left: 30px;
        padding-left: 0px;
        max-width: 200px;
        min-width: 200px;
    }
    .navbar-collapse {
        padding-right: 0px;
    }
    .navbar {
        padding-right: 0rem;
    }
    .dropdown-menu {
        text-align: center !important;
    }
    .justify-content-end {
        padding-right: 0px;
    }
    .button-continer {
        align-items: center;
    }
}

@media (max-width: 780px) {
    .navbar-brand {
        font-size: 1.0rem;
    }
    .nav-brand:hover {
        text-decoration: none;
        color: white;
        font-size: 1.0rem;
    }
    /* Theme of the UE main website is to highlight the link to the current page in yellow  */
    .yellow-link {
        font-size: 1.50rem;
    }
}
@media (max-width: 780px) {
    .logo {
        margin-left: 15px;
        padding-left: 0px;
        max-width: 200px;
        min-width: 200px;
    }
    .navbar-brand {
        font-size: 1.0rem;
    }
    .nav-brand:hover {
        text-decoration: none;
        color: white;
        font-size: 2.25rem;
    }
}
@media (max-width: 400px) {
    .yellow-item {
        font-size: 1.25rem;
    }
}
/* Note:  Content and layout is largely copied and pasted from ue-main-website.
        File:  utopia-experiences-webflow.css  Changes from original are commented
        ue-main-website was created through WebFlow and not all classes are avaialble in
        the exported style sheets.   */

/* BTW:  Copy and pasting code is not good practice, but this is a startup and we have a
          lot of work to do and we're short funds right now! */

.w-container {      /* inserted for react ue-host-page format */
    padding-left: 10rem;
    padding-right: 10rem;
    text-align: center;
}

.w-list-unstyled {     /* inserted for react ue-host-page format */
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer-flex-container {
    display: flex;
    margin-top: 40px;   /* inserted for react ue-host-page format */
    margin-bottom: 40px;
    justify-content: space-between;
    text-align: left;
  }

  .footer-logo-link {
    max-height: 60px;
    min-width: 60px;
    flex: 0 1 auto;
  }

  .footer-image {
    border-radius: 0%;
    object-fit: contain;
    object-position: 0% 50%;
  }

  .footer-heading {
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: bold;   /* inserted for react ue-host-page format */
    line-height: 1.5;
  }

  .footer-link {
    display: block;
    margin-bottom: 10px;
    color: #444;
    text-decoration: none;
  }

  .footer-link:hover {
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    .w-container {      /* inserted for react ue-host-page format */
      padding-left: 5rem;
      padding-right: 5rem;
      text-align: center;
    }
  }
  @media (max-width: 825px) {
    .w-container {      /* inserted for react ue-host-page format */
      margin-left: -60px;
      padding-right: 0rem;
      text-align: left;
      width:  100% !important;
    }
    #footer {
      margin-left: 0px;
      padding-left: 0px;
    }
    .footer-flex-container {
      display: flex;
      margin-top: 40px;   /* inserted for react ue-host-page format */
      margin-bottom: 40px;
      padding-left: 0rem;
      justify-content: space-between;
      text-align: left;
    }
  }
  @media screen and (max-width: 730px) {
    .footer-flex-container {
    flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
  .footer-flex-container {
    display: flex;
    margin-top: 20px;   /* inserted for react ue-host-page format */
    margin-bottom: 40px;
    justify-content: space-between;
    text-align: left;
  }
}
