/* Note:  Content and layout is largely copied and pasted from ue-main-website.
        File:  utopia-experiences-webflow.css  Changes from original are commented
        ue-main-website was created through WebFlow and not all classes are avaialble in
        the exported style sheets.   */

/* BTW:  Copy and pasting code is not good practice, but this is a startup and we have a
          lot of work to do and we're short funds right now! */

.w-container {      /* inserted for react ue-host-page format */
    padding-left: 10rem;
    padding-right: 10rem;
    text-align: center;
}

.w-list-unstyled {     /* inserted for react ue-host-page format */
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer-flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;   /* inserted for react ue-host-page format */
    margin-bottom: 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
  }

  .footer-logo-link {
    max-height: 60px;
    min-width: 60px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .footer-image {
    border-radius: 0%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 0% 50%;
    object-position: 0% 50%;
  }

  .footer-heading {
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: bold;   /* inserted for react ue-host-page format */
    line-height: 1.5;
  }

  .footer-link {
    display: block;
    margin-bottom: 10px;
    color: #444;
    text-decoration: none;
  }

  .footer-link:hover {
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    .w-container {      /* inserted for react ue-host-page format */
      padding-left: 5rem;
      padding-right: 5rem;
      text-align: center;
    }
  }
  @media (max-width: 825px) {
    .w-container {      /* inserted for react ue-host-page format */
      margin-left: -60px;
      padding-right: 0rem;
      text-align: left;
      width:  100% !important;
    }
    #footer {
      margin-left: 0px;
      padding-left: 0px;
    }
    .footer-flex-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 40px;   /* inserted for react ue-host-page format */
      margin-bottom: 40px;
      padding-left: 0rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      text-align: left;
    }
  }
  @media screen and (max-width: 730px) {
    .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
  .footer-flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;   /* inserted for react ue-host-page format */
    margin-bottom: 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
  }
}